@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~bootstrap/scss/bootstrap";
@import "~react-toastify/dist/ReactToastify.min.css";

@import "styles/fonts.scss";
@import "styles/variables.scss";
@import "styles/pages";


html {
  overflow-y: auto;
  overflow-x: hidden;
}

body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: $roboto-light;
}

a {
  transition: all ease-in-out 0.3s;
}

img {
  max-width: 100%;
}

button {
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
}

.chart-container {
  position: relative;
  height: 60vh;
  margin-bottom: 20px;
  //max-width: 750px;

  @include media-breakpoint-up(md) {
    height: 60vh;
    margin-bottom: 30px;

    max-width: 750px;
  }

  &.onboard {
    margin-bottom: 10px;
  }
}

.column {
  float: left;
  width: 33.33%;
  padding: 10px;
  height: 200px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}


//canvas {
//  margin-bottom: 20px;
//  max-width: 750px;
//
//  @include media-breakpoint-up(md) {
//    margin-bottom: 30px;
//  }
//}

h2 {
  color: $color-blue1;
  font-weight: 300;
  font-family: $roboto-light;
  font-size: 23px;

  @include media-breakpoint-up(lg) {
    font-size: 28px;
  }
}

h4 {
  color: $color-black;

  &.number-big {
    font-size: 40px;
    font-weight: 700;

    sup {
      font-size: 20px;
    }

    @include media-breakpoint-up(md) {
      font-size: 58px;

      sup {
        top: -30px;
      }
    }
  }
}

p {
  &.number-big {
    font-weight: 700;
    color: $color-black;
    margin-bottom: 0;
    font-size: 25px;

    @include media-breakpoint-up(md) {
      font-size: 32px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 38px;
    }
  }
}

.MuiFormControlLabel-root {
  .MuiTypography-root {
    font-weight: 300;
    font-size: 17px;
    color: $color-grey;
  }

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: $color-blue;
  }

  .MuiCheckbox-root {
    color: $color-blue;
  }
}

.MuiFormControl-root {
  label {
    font-weight: 300;
    font-size: 17px;
    color: $color-grey;

    &.Mui-focused {
      color: $color-blue;
    }
  }

  .MuiInput-underline {
    &:after,
    &:before {
      border-bottom: 2px solid $color-blue;
    }
  }
}

.nav-filter {
  .nav-link {
    background: transparent;
    border: none;
    padding: 0 10px;
    color: $color-blue;
    font-weight: 300;
    outline: none !important;
    font-size: 10px;

    &.active {
      font-weight: 700;
    }
  }
}
.success-dialog {
  background: rgba(45, 141, 54, 0.8);
  text-align: center;
}
.duplicate-dialog {
  background: rgba(152, 17, 17, 0.8);
  text-align: center;
  color: white;
}
#alert-dialog-description {
  color: white;
}
.dialog-custom,
.MuiBox-root {
  .MuiDialogContent-root {
    overflow-x: hidden;

    @include media-breakpoint-up(lg) {
      overflow: hidden;
    }
  }

  .MuiPaper-rounded {
    border-radius: 13px;
  }

  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.02);
  }

  .MuiDialog-paperWidthMd {
    margin: 15px;

    @include media-breakpoint-up(md) {
      min-width: 500px;
    }

    @include media-breakpoint-up(lg) {
      min-width: 650px;
    }

    .map-container {
      min-height: 195px;
    }

    .MuiDialogTitle-root {
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(230, 230, 230, 1) 53%, rgba(255, 255, 255, 1) 100%);

      span {
        color: $color-blue1;
        font-weight: 300;
        font-family: $roboto-light;
        font-size: 23px;

        @include media-breakpoint-up(lg) {
          font-size: 28px;
        }
      }

      p {
        font-family: $roboto-reguler;
        font-size: 13px;
        color: $color-black;

        @include media-breakpoint-up(lg) {
          font-size: 15px;;
        }
      }
    }
  }

  label.title {
    font-family: $roboto-medium;
    color: $color-black;
    font-size: 15px;
    margin-bottom: 0;
    display: block;

    @include media-breakpoint-up(lg) {
      font-size: 17px;;
    }
  }

  i {
    &.status {
      font-style: normal;
    }
  }

  p {
    font-size: 13px;
    color: $color-black;

    @include media-breakpoint-up(lg) {
      font-size: 15px;
      line-height: 20px;
    }
  }
}

.text-danger {
  font-size: 14px;
}

.dashboard-container {
  margin-left: 200px;
  padding: 20px;
}

.capitalize-text {
  text-transform: capitalize;
}

.card{
  border: 0px transparent ;
  background: $color-white 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000029;
  border-radius: 8px;
  padding:20px;
}

.content-center {
  display: flex;
  justify-content: center;
  ///align-items: center;
}

.credit-card{
  background: linear-gradient(90deg, #9EAABF, #6C8096);
  border-radius: 8px;
  box-shadow: 0px 4px 8px #92BEC655;
}