$color-blue         : #008299;
$color-blue1        : #455A63;
$color-black        : #4C4C4D;
$color-grey         : #B2B2B2;
$color-grey1        : #F1F1F1;
$color-grey2        : #b2b2b2;
$color-grey3        : #e6e6e6;
$color-lightgrey    : rgba(0, 0, 0, 0.16);
$color-white        : #FFFFFF;
$color-grey-white   : #FAFAFA;
$color-light-green  : #B9D4E0;
$color-green        : #06b300;
$color-red          : #ff3434;



//Fonts
$roboto-light    : 'Roboto Light';
$roboto-medium   : 'Roboto Medium';
$roboto-reguler  : 'Roboto Reguler';
$gibson          : 'Gibson';
$gibson-semi     : 'Gibson Semibold';
$gibson-light    : 'Gibson Light';
$source-sans     : 'Source Sans Pro';
$clear-sans      : 'Clear Sans';

.btn {
  border: none;
  padding: 3px 15px;
  font-size: 14px;
  font-family: $source-sans;

  @include media-breakpoint-up(lg){
    font-size: 15px;
    padding: 3px 30px;
  }

  &:hover,
  &:focus {
    outline: none;
  }

  &-promo {
    background: rgb(23, 162, 184);
    color: white;
  }

  &-blue {
    background: $color-blue;
    color: $color-white;
    border: 1px solid $color-blue;
    border-radius: 3px;

    .login & {
      padding: 15px;
      width: 100%;

      @include media-breakpoint-up(md){
        font-size: 20px;
      }
    }

    &:hover {
      background: transparent;
      color: $color-blue;
      border: 1px solid;
    }

    &-trans {
      background: transparent;
      color: $color-blue;
      border: 1px solid;
      border-radius: 1px;

      &:hover {
        background: $color-blue;
        color: $color-white;
        border: 1px solid $color-blue;
      }
    }
  }

  &.full {
    width: 100%;
  }

  &-close {
    background: transparent;
    border: none;
    color: $color-blue;
    position: absolute;
    right: 15px;
    top: 15px;
  }
  &-red-outline {
    background: transparent;
    color:  $color-red;
    border: 1px solid $color-red;
    border-radius: 3px;
  }
  &-white{
    background: $color-white;
    color:  $color-green;
    border: 1px solid $color-white;
    border-radius: 3px;
    &:hover {
      background: $color-blue;
      color: $color-white;
      border: 1px solid $color-blue;
    }
  }
  &-green{
    background: $color-green;
    color:  $color-white;
    border: 1px solid $color-white;
    border-radius: 3px;
    &:hover {
      background: $color-blue;
      color: $color-white;
      border: 1px solid $color-blue;
    }
  }
}

.text {
  &-blue {
    color: $color-blue !important;
  }
  &-title-big{
    color: $color-black;
    font-size: 24px;
    font-family: $roboto-medium ;
    font-weight: bold;
  }
  &-title{
    color: $color-black;
    font-size: 18px;
    font-weight: bold;
  }
  &-black{
    color: $color-black;
    font-size: 14px;
    &.bold{
      font-weight: bold;
    }
    &.num15{
      font-size: 15px;
    }
    &.num18{
      font-size: 18px;
    }
  }
  &-white{
    color:$color-white;
    font-weight: bold;
    font-size: 15px;
    &.num18{
      font-size: 18px;
    }
  }
  
  
  
}

.table-list {
  thead{
    tr {
      border-bottom: 1px solid;

      th {
        font-size: 15px;
        color: $color-black;
        font-family: $clear-sans;
        padding: 5px 10px;
      }
    }
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      background-color: $color-grey-white;

      &:hover,
      &.Mui-selected{
        background-color: $color-light-green;
      }

      &:nth-child(even) {
        background-color: $color-white;

        &:hover,
        &.Mui-selected{
          background-color: $color-light-green;
        }
      }

      td {
        border: none;
        color: $color-black;
        font-family: $clear-sans;
        padding: 5px 10px;
        letter-spacing: 0;

        strong {
          font-family: $gibson-semi;
        }
      }
    }
  }
}
